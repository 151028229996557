<template>
  <b-button @click="activar">{{texto}}</b-button>
</template>

<script>
export default {
  name: "BotonNuevo",
  props: {
    texto: String
  },
  methods: {
    activar() {
      this.$emit('accion')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";
  button {
    font-size: 0.8rem; 
  }
  
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
</style>
