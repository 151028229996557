<template>
  <div>
    <b-modal
      ref="modal-avisos"
      size="lg"
      hide-footer
      hide-header
      title="Avisos"
    >
      <div class="d-block">
        <img
          class="logocongresomodal"
          src="../assets/logos/XVI-logo-anio2-gris-2020.svg"
          alt=""
        />
        <h5 class="titulo-modal">Avisos</h5>
        <div class="container container-avisos">
          <div
            class="aviso shadow-sm"
            v-for="a in avisos"
            :key="a.key"
            v-html="a.aviso"
          ></div>
        </div>
      </div>
    </b-modal>
    <div class="container-fluid container-marco-inicio">
      <div class="container">
        <div class="container-bienvenida">
          <h1 class="titulo-micrositio"><span>Bienvenidos al micrositio del <br /></span> Ley de Consulta a los Pueblos Indígenas y Afroamericanos.</h1>
          <b-button 
            class="boton-bienvenida" 
            href="https://storage.googleapis.com/documentos.congresoqroo.gob.mx/estrados/Estrado_20220316_Convocatoria-para-participar-en-la-consulta,-previa,-libre,-informada-y.pdf"
            target="_blank"
          >
            Ver Convocatoria
          </b-button>
        </div>
        
      </div>
    </div>
    <PubHomeSecciones />
  </div>
</template>

<script>
import firebase from "../firebase";
//import router from "../router";
import PubHomeSecciones from "../components/PubHomeSecciones.vue"
import AtomLogoSol from "../components/AtomLogoSol.vue"

export default {
  name: "Home",
  components: {
    PubHomeSecciones,
    AtomLogoSol
  },
  data() {
    return {
      show: false,
      refAvisos: firebase.firestore().collection("avisos"),
      avisos: {},
      modal: false,
    };
  },
  created() {
    this.refAvisos.where("activo", "==", true).onSnapshot((querySnapshot) => {
      this.avisos = [];
      querySnapshot.forEach((doc) => {
        this.avisos.push({
          key: doc.id,
          aviso: doc.data().aviso,
        });
      });

      if (this.avisos.length != 0) {
        this.showModal();
      }
    });
  },
  methods: {
    showModal() {
      this.$refs["modal-avisos"].show();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";

.container-marco-inicio {
  padding: 6rem 1rem 0.5rem;
  margin-bottom: 2rem;
  height: 400px;
  display: flex;
  justify-content: center;
  background-color: FBAB7E;
  background: url(../assets/logoMascaron.svg) bottom -220px center no-repeat,
              url(../assets/fondo-ilustracion.svg) bottom -430px center no-repeat;
  background-size: 360px, 550px;

  @include media-breakpoint-up(md) {
    padding: 6rem 1rem 2rem;
    height: 600px;
    background: url(../assets/logoMascaron.svg) bottom -230px center no-repeat,
                url(../assets/fondo-ilustracion.svg) bottom -500px center no-repeat;
    background-size: 460px, 660px;
  }

  .container-bienvenida {
    text-align: center;
    .titulo-micrositio {
      font-family: $font-03;
      font-size: 1.1rem;
      text-transform: uppercase;
      margin: 0 auto;
      margin-top: 1rem;
      max-width: 400px;
      @include media-breakpoint-up(md) {
        margin-top: 3rem;
        font-size: 2.5rem;
        max-width: 800px;
      }
      span {
        font-family: $font-02;
        font-size: 0.8rem;
        font-weight: 300;
        @include media-breakpoint-up(md) {
          font-size: 1rem;
        }
      }
    }
    p {
      font-size: 0.7rem;
      font-weight: 300;
      @include media-breakpoint-up(md) {
        font-size: 0.85rem;
      }
    }
    .boton-bienvenida {
      background: white;
      border: 1px solid black;
      color: black;
      font-size: 0.85rem;
      padding:0.75rem 2.5rem;
      border-radius: 0;
      margin-top: 1rem;

      &:hover {
        border: 1px solid brown;
        color: brown;
      }
    }
  }
}

</style>
