<template>
  <div>
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado links
    </p>
    <ul v-else>
      <li v-for="i in items" :key="i.key">
        <div v-if="i.edit === false" class="px-4 align-items-center lista-secciones">
          <span class="orden">{{i.orden}}</span>
          <a class="link" :href="i.url" target="_black">{{i.nombre}}</a>
          <b-button
            class="button-edit"
            v-b-tooltip.hover
            title="Editar"
            @click="setEditLink(i.key)"
          >
            <b-icon icon="pen" aria-hidden="true"></b-icon>
          </b-button>
          <b-button
            class="button-delete"
            @click.stop="deleteLink(i.key)"
            v-b-tooltip.hover
            title="Eliminar"
          >
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-button>
        </div>
      
        <div v-else>
          <div class="container-details-articulo row">
            <div class="form-group col-md-1">
              <b-form-input
                v-model.trim="i.orden"
                type="number"
                placeholder="Orden"
              ></b-form-input>
            </div>
            <div class="form-group col-md-4">
              <b-form-input
                v-model.trim="i.nombre"
                type="text"
                placeholder="Nombre"
              ></b-form-input>
            </div>
            <div class="form-group col-md-5">
              <b-form-input
                v-model="i.url"
                type="url"
                placeholder="Url"
              ></b-form-input>
            </div>
            <div class="form-group col-md-2 d-flex">
              <div class="container-botton-inline">
                <b-button class="button-accept-sm" @click="setUpdateLink(i)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                  </svg>
                </b-button>
                <b-button class="button-cancel-sm" @click="cancelEditLink(i.key)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                    <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                  </svg>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "../firebase";
import router from "../router";

export default {
  name: "AdminLinkLista",
  props: ["seccion", "subseccion", "publicacion"],
  data() {
    return {
      ref: firebase.firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones")
        .doc(this.publicacion)
        .collection("links"),
      items: {},
      showEdit: true,
      enabled: false,
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });
      //console.log(this.items)
    });
  },
  methods: {
    details(subseccion) {
      router.push({ name: "AdminSubseccion", params: { id: this.seccion, subid: subseccion } });
    },
    setEditLink(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    setUpdateLink(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          orden: id.orden,
          nombre: id.nombre,
          url: id.url,
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
          this.enabled === false;
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    cancelEditLink(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteLink(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Documento?",
        text: "¡Una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#657f61",
        cancelButtonColor: "#99494b",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("secciones")
            .doc(this.seccion)
            .collection("subsecciones")
            .doc(this.subseccion)
            .collection("publicaciones")
            .doc(this.publicacion)
            .collection("links")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error al eliminar el documento: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
    getURLFile: function(url) {
      this.urlFile = url;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";
  .orden {
    background-color: #6c757d;
    border-radius: 2px;
    width: 1.75rem;
    display: inline-block;
    text-align: center;
    margin-right: 0.5rem;
    color: white;
    font-size: 0.75rem;
  }
  .link {
    text-decoration: none;
    color: $color-text;
    margin-right: 1rem;
    &:hover {
      color: $color-01;
    }
  }
</style>
