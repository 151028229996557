<template>
  <div class="container-lista-links">
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado Archivos
    </p>
    <ul v-else class="lista-links">
      <li
        v-for="i in items" :key="i.key"
        class="text-center"
      >
        <template v-if="i.edit === false">
          <a :id="i.key" :href="i.url" target="_blank">
            <template v-if="i.tipo === 'pdf'">
              <img class="logoTipoArchivo" src="../assets/logos/pdf.svg" alt="Logo PDF">
            </template>
            <template v-else-if="i.tipo === 'doc'">
              <img class="logoTipoArchivo" src="../assets/logos/doc.svg" alt="Logo Word">
            </template>
            <template v-else-if="i.tipo === 'xls'">
              <img class="logoTipoArchivo" src="../assets/logos/xls.svg" alt="Logo Excel">
            </template>
            <template v-else-if="i.tipo === 'audio'">
              <img class="logoTipoArchivo" src="../assets/logos/mp3.svg" alt="Logo Audio">
            </template>
            <template v-else>
              <img class="logoTipoArchivo" src="../assets/logos/archivo.svg" alt="Logo Archivo">
            </template>
          </a>
          <b-tooltip v-if="i.nombrearchivo" :target="i.key" triggers="hover">
            {{ i.nombrearchivo }}
          </b-tooltip>
          <div>
            <b-button
              class="button-delete-link"
              @click.stop="deleteLink(i.key)"
              v-b-tooltip.hover
              title="Eliminar"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  props: ["seccion", "subseccion", "publicacion", "documento"],
  data() {
    return {
      ref: firebase.firestore()
        .collection("secciones").doc(this.seccion)
        .collection("subsecciones").doc(this.subseccion)
        .collection("publicaciones").doc(this.publicacion)
        .collection("documentos").doc(this.documento)
        .collection("urls"),
      items: {},
      urlFile: "",
    };
  },
  created() {
    this.ref.onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });
      //console.log(this.items)
    });
  },
  methods: {
    deleteLink(id) {
      this.ref
        .doc(id)
        .collection("documentos")
        .get()
        .then((snapshot) => {
          let size = snapshot.docs.length;
          if (size != 0) {
            this.$swal.fire({
              icon: "error",
              title: "No se puede eliminar esta sección.",
              text:
                "Esta Sección continene información, asegurese de eliminar primero las publicaciones.",
            });
          } else {
            this.$swal({
              title: "¿Realmente quiere eliminar esta Sección?",
              text:
                "¡Esto eliminará todo el contenido de la sección, una vez eliminada ya no podrá recuperar la información!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#657f61",
              cancelButtonColor: "#99494b",
              confirmButtonText: "Si, eliminar",
              cancelButtonText: "No, cancelar",
              //closeOnCancel: true
            }).then((result) => {
              //send request to server
              if (result.isConfirmed) {
                this.ref
                  .doc(id)
                  .delete()
                  .then(() => {
                    //console.log("Eliminado")
                  })
                  .catch((error) => {
                    alert("Error removing document: ", error);
                  });
                this.$swal(
                  "Registro Eliminado",
                  "El registro ha sido eliminado con éxito.",
                  "success"
                );
              }
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .logoTipoArchivo {
    width: 2rem;
    height: auto;
  }

  
</style>