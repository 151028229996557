<template>
  <div class="container">
    <MenuAdmin />
    <!--<code>&lt;AdminSubseccion.vue&gt;</code>-->
    <h1 class="titulo-seccion">{{ seccion.name }}</h1>
    <p class="descripcion">{{ seccion.description }}</p>
    <h5 class="titulo-subseccion">{{ subseccion.titulo}}</h5>
    <div class="container-button-add" v-if="!show">
      <BotonNuevo texto="Agregar Publicación" @accion="activar" />
    </div>
    <template>
      <div v-if="show" class="container-new-document">
          <AdminPublicacionNueva
            :seccion="this.$route.params.id"
            :subseccion="this.$route.params.subid"
            @accion="cancelar"
          />
      </div>
      <div class="container-list-documents">
        <h3>Lista de Publicaciones</h3>
        <AdminPublicacionLista :seccion="this.$route.params.id" :subseccion="this.$route.params.subid" />
      </div>
    </template>
  </div>
</template>

<script>
import firebase from "../firebase";
import router from "../router";
import MenuAdmin from "../components/MenuAdmin";
import AdminPublicacionNueva from "../components/AdminPublicacionNueva";
import AdminPublicacionLista from "../components/AdminPublicacionLista";
import BotonNuevo from "../components/BotonNuevo.vue";

export default {
  name: "AdminSeccion",
  components: {
    MenuAdmin,
    AdminPublicacionNueva,
    AdminPublicacionLista,
    BotonNuevo,
  },
  data() {
    return {
      key: "",
      seccion: {},
      subseccion: {},
      show: false,
    };
  },
  methods: {
    cancelar() {
      this.show = false;
    },
    activar() {
      this.show = true;
    }
  },
  created() {
    const refSeccion = firebase.firestore().collection("secciones").doc(this.$route.params.id)
    const ref = firebase.firestore().collection("secciones").doc(this.$route.params.id).collection("subsecciones").doc(this.$route.params.subid);
    refSeccion.get().then((doc) => {
      if (doc.exists) {
        this.key = doc.id;
        this.seccion = doc.data();
      } else {
        alert("No existe la sección!");
      }
    });
    ref.get().then((doc) => {
      if (doc.exists) {
        this.subkey = doc.id;
        this.subseccion = doc.data();
      } else {
        alert("No existe la sección!");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";
.logo-seccion {
  width: 80px;
  margin: auto;
  padding-top: 2rem;
}
.titulo-seccion {
  text-align: center;
  font-size: 1.85rem;
  font-family: $font-01;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}
.descripcion {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.75rem;
}
.container-list-documents {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  h3 {
    font-size: 1.25rem;
    font-family: $font-01;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
