<template>
  <b-form @submit.prevent="onSubmit" class="align-items-center lista-secciones">
    <h3 class="titulo-nuevo-articulo">Nuevo Aviso.</h3>
    <div class="container-details-articulo">
      <div class="container-activar">
        <p>¿Activar Aviso?</p>
        <switches v-model="avisos.activo" color="blue"></switches>
      </div>
      <div class="container-botton-inline">
        <b-button class="button-accept-sm" v-if="avisos.aviso" type="submit">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
          </svg>
        </b-button>
        <b-button class="button-cancel-sm" @click="cancelar">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
            <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
          </svg>
        </b-button>
      </div>
    </div>
    <vue-editor id="description" v-model="avisos.aviso" />
  </b-form>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import { VueEditor } from "vue2-editor";

export default {
  name: "NewSection",
  components: {
    VueEditor,
    Switches,
  },
  data() {
    return {
      ref: firebase.firestore().collection("avisos"),
      avisos: {},
      selected: "",
    };
  },
  methods: {
    cancelar() {
      this.$emit('accion')
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.avisos.edit = false;
      this.ref
        .add(this.avisos)
        .then((docRef) => {
          (this.avisos.aviso = ""), (this.avisos.activo = "");
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    },
  },
};
</script>
