<template>
  <div class="container home">
    <MenuAdmin />
    <!--<code>&lt;AdminPublicaciones.vue&gt;</code>-->
    <b-tabs content-class="mt-3">
      <b-tab title="Secciones" active>
        <div class="container-button-add">
          <b-button v-if="showSection" v-on:click="showSection = !showSection">
            <transition name="fade">
              <span>Cancelar</span>
            </transition>
          </b-button>
          <b-button v-if="!showSection" v-on:click="showSection = !showSection">
            <transition name="fade">
              <span>Agregar Sección</span>
            </transition>
          </b-button>
        </div>
        <div v-if="showSection" class="container-nueva-seccion">
          <transition name="fade">
            <AdminSeccionNueva v-if="showSection" />
          </transition>
        </div>
        <div class="container-lista-secciones">
          <h3>Lista de Secciones del Micrositio</h3>
          <transition name="fade">
            <AdminSeccionLista />
          </transition>
        </div>
      </b-tab>
      <b-tab title="Avisos">
        <div v-if="!showAviso" class="container-button-add">
          <b-button v-on:click="showAviso = !showAviso">
            <transition name="fade">
              <span>Nuevo Aviso</span>
            </transition>
          </b-button>
        </div>
        <div v-if="showAviso" class="container-nuevo-aviso">
          <transition name="fade">
            <NewAviso v-if="showAviso" @accion="cancelarEdicionAviso"/>
          </transition>
        </div>
        <div class="container-list-documents">
          <h3>Lista de Avisos</h3>
          <transition name="fade">
            <ListAvisos/>
          </transition>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import NewAviso from "../components/NewAviso";
import AdminSeccionNueva from "../components/AdminSeccionNueva";
import AdminSeccionLista from "../components/AdminSeccionLista";
import ListAvisos from "../components/ListAvisos";
import MenuAdmin from "../components/MenuAdmin";

export default {
  name: "Admin",
  components: {
    AdminSeccionNueva,
    NewAviso,
    AdminSeccionLista,
    ListAvisos,
    MenuAdmin,
  },
  data() {
    return {
      showSection: false,
      showAviso: false,
    };
  },
  methods: {
    cancelarEdicionAviso() {
      this.showAviso = false;
    }
  }
};
</script>

<style lang="scss">
  .home {
    margin-top: 5rem;
  }

  .container-button-add {
    margin: 2rem 1rem;
    text-align: center;;
  }
  .container-nueva-seccion {
    margin-bottom: 2rem;
  }
  .container-lista-secciones {
    h3 {
      font-size: 1.2rem;
      margin-bottom: 2rem;;
    }
  }

  .container-nuevo-aviso {
    margin-bottom: 2rem;
  }
</style>