<template>
  <div>
    <p v-if="avisos.length == 0" class="text-center">
      Aún no se han publicado Avisos.
    </p>
    <ul v-else>
      <li class="mb-4"
        v-for="i in avisos" :key="i.key"
      > 
        <div v-if="i.edit === false" class="px-4 align-items-center lista-secciones">
          <div class="titulo-documento">
            <span class="estatus-articulo">Estatus:
              <code v-if="i.activo" class="estatus-activo">Publicado</code>
              <code v-else class="estatus-inactivo">No Publicado</code>
            </span>
            <b-button
              class="button-edit"
              v-b-tooltip.hover
              title="Editar"
              @click="setEditAviso(i.key)"
            >
              <b-icon icon="pen" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              class="button-delete"
              @click.stop="deleteAviso(i.key)"
              v-b-tooltip.hover
              title="Eliminar"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
          </div>
          <div class="container-aviso" v-html="i.aviso"></div>
        </div>

        <div v-else>
          <div class="container-details-articulo">
            <b-form-input
              class="input-orden-articulo"
              v-model.number="i.orden"
              type="number"
              placeholder="Orden"
            ></b-form-input>
            <div class="container-activar">
              <p>¿Activar Aviso?</p>
              <switches v-model="i.activo" color="blue"></switches>
            </div>
            <div class="container-botton-inline">
              <b-button class="button-accept-sm" @click="setUpdateAviso(i)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                </svg>
              </b-button>
              <b-button class="button-cancel-sm" @click="cancelEditAviso(i.key)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                  <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                </svg>
              </b-button>
            </div>
          </div>
          <vue-editor id="description" v-model="i.aviso" />
        </div>

      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "../firebase";
import router from "../router";
import Switches from "vue-switches";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
    Switches,
  },
  data() {
    return {
      ref: firebase.firestore().collection("avisos"),
      avisos: {},
      enabled: false,
    };
  },
  created() {
    this.ref.onSnapshot((querySnapshot) => {
      this.avisos = [];
      querySnapshot.forEach((doc) => {
        this.avisos.push({
          key: doc.id,
          aviso: doc.data().aviso,
          activo: doc.data().activo,
          edit: doc.data().edit,
        });
      });
      //console.log(this.items)
    });
  },
  methods: {
    setEditAviso(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se Actualizó la sección")
        })
        .catch((error) => {
          alert("Error al actualizar la sección. ", error);
        });
    },
    setUpdateAviso(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          aviso: id.aviso,
          activo: id.activo,
          edit: false,
        })
        .then(() => {
          this.enabled = false;
        })
        .catch((error) => {
          alert("Error al Actualizar el documento. ", error);
        });
    },
    cancelEditAviso(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteAviso(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Aviso?",
        text:
          "¡Esto eliminará el aviso, una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#657f61",
        cancelButtonColor: "#99494b",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("avisos")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error removing document: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";
  .container-aviso {
    /deep/ h1 {
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
      margin: 1.5rem 5rem;
    }
    /deep/ h2 {
      font-size: 1.2rem;
      font-weight: 700;
      text-align: center;
      margin: 1.5rem 5rem;
    }
    /deep/ p {
      font-weight: 300;
      text-align: justify;
    }
  }
</style>
