<template>
  <b-form @submit.prevent="onSubmit" class="d-flex align-items-center lista-secciones">
    <!--<code>&lt;AdminPublicacionNueva.vue&gt;</code>-->
    <b-form-input
      class="input-orden-new"
      v-model.number="publicacion.orden"
      type="number"
      placeholder="Orden"
    ></b-form-input>
    <b-form-input
      v-model.trim="publicacion.titulo"
      type="text"
      placeholder="Título de la publicacion (opcional)"
    ></b-form-input>
    <div class="container-botton-inline">
      <b-button class="button-accept-sm" v-if="publicacion.orden" type="submit">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </b-button>
      <b-button class="button-cancel-sm" @click="cancelar">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
          <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
        </svg>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "AdminPublicacionNueva",
  props: ["seccion", "subseccion"],
  data() {
    return {
      ref: firebase.firestore().collection("secciones").doc(this.seccion).collection("subsecciones").doc(this.subseccion).collection("publicaciones"),
      publicacion: {},
      enabled: false,
    };
  },
  methods: {
    cancelar() {
      this.$emit('accion')
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.publicacion.edit = false;
      
      this.ref
        .add(this.publicacion)
        .then((docRef) => {
          (this.publicacion.titulo = ""),
          (this.publicacion.orden = null),
          (this.enabled = false),
          (this.edit = false);
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    }
  },
};
</script>

<style></style>
