import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const settings = { timestampsInSnapshots: true };

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBTUcSZbE3bHZIvevY8M0Blrnw87_g681s",
  authDomain: "micrositio-cpia.firebaseapp.com",
  projectId: "micrositio-cpia",
  storageBucket: "micrositio-cpia.appspot.com",
  messagingSenderId: "478351231493",
  appId: "1:478351231493:web:737d21de01959263c32a6c",
  measurementId: "G-ZRE8RGY6KV"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings(settings);

export default firebase;