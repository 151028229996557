
<template>
    <b-form @submit.prevent="onSubmit" class="d-flex align-items-center lista-secciones">
      <!--<code>&lt;AdminSubseccion.vue&gt;</code>-->
      <b-form-input
        class="input-orden-new"
        v-model.number="doc.orden"
        type="number"
        placeholder="Orden"
      ></b-form-input>
      <b-form-input
        v-model.trim="doc.titulo"
        type="text"
        placeholder="Ingrese el título de la Subsección"
      ></b-form-input>
      <div class="container-botton-inline">
          <b-button class="button-accept-sm" v-if="doc.titulo && doc.orden" type="submit">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
            </svg>
          </b-button>
          <b-button class="button-cancel-sm" @click="cancelar">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
              <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
            </svg>
          </b-button>
      </div>
    </b-form>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "AdminSeccionNueva",
  props: ["seccion"],
  data() {
    return {
      ref: firebase.firestore().collection("secciones").doc(this.seccion).collection("subsecciones"),
      doc: {},
      enabled: false,
    };
  },
  methods: {
    cancelar() {
      this.$emit('accion')
    },
    onSubmit(evt) {
      evt.preventDefault();
      (this.doc.edit = false);

      this.ref
        .add(this.doc)
        .then((docRef) => {
          (this.doc.titulo = ""),
          (this.doc.orden = null),
          (this.edit = false);
        })
        .catch((error) => {
          alert("Error al agregar el documento: ", error);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";
.container-switch {
  text-align: center;
  p {
    margin-top: 0.5rem;
  }
}
</style>
