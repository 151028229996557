<template>
  <b-form @submit.prevent="onSubmit" class="align-items-center lista-secciones">
    <!--<code>&lt;AdminarticuloNuevo.vue&gt;</code>-->
    <h3 class="titulo-nuevo-articulo">Nuevo Artículo.</h3>
    
    <div class="container-details-articulo row">
      <div class="form-group col-md-10">
        <b-form-input
          v-model.trim="articulo.titulo"
          type="text"
          placeholder="Titulo"
        ></b-form-input>
      </div>
      <div class="form-group col-md-2">
        <b-form-input
          v-model.number="articulo.orden"
          type="number"
          placeholder="Orden"
        ></b-form-input>
      </div>
      <div class="form-group col-md-2">
        <b-form-input
          v-model="articulo.fecha"
          type="date"
          placeholder="Fecha"
        ></b-form-input>
      </div>
      <div class="form-group col-md-2">
        <b-form-input
          class="col-2"
          v-model="articulo.hora"
          type="time"
          placeholder="Hora"
        ></b-form-input>
      </div>
      <div class="form-group col-md-4">
        <b-form-input
          class="col-2"
          v-model="articulo.lugar"
          type="text"
          placeholder="lugar"
        ></b-form-input>
      </div>
      <div class="form-group col-md-4 d-flex">
        <div class="container-activar">
          <p>¿Activar Artículo?</p>
          <switches v-model="articulo.activo" color="blue"></switches>
        </div>

        <div class="container-botton-inline">
          <b-button class="button-accept-sm" v-if="articulo.articulo && articulo.orden" type="submit">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
            </svg>
          </b-button>
          <b-button class="button-cancel-sm" @click="cancelar">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
              <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
            </svg>
          </b-button>
        </div>
      </div>
      
    </div>
    <vue-editor id="description" v-model="articulo.articulo" />
  </b-form>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import { VueEditor } from "vue2-editor";

export default {
  name: "AdminArticuloNuevo",
  props: ["seccion", "subseccion", "publicacion"],
  components: {
    VueEditor,
    Switches,
  },
  data() {
    return {
      ref: firebase.firestore()
      .collection("secciones").doc(this.seccion)
      .collection("subsecciones").doc(this.subseccion)
      .collection("publicaciones").doc(this.publicacion)
      .collection("articulos"),
      articulo: {},
      orden: null,
      enabled: false,
    };
  },
  methods: {
    cancelar() {
      this.$emit('accion')
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.articulo.edit = false;
      
      this.ref
        .add(this.articulo)
        .then((docRef) => {
          this.articulo.titulo = "",
          this.articulo.fecha = null,
          this.articulo.hora = null,
          this.articulo.lugar = "",
          this.articulo.articulo = "",
          this.articulo.orden = null,
          this.articulo.activo = false,
          this.enabled = false,
          this.edit = false;
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";
  
  .container-nuevo-documento {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: $color-bg-02;
    padding: 1rem;

    @include box_shadow(1);
  }
  

</style>
