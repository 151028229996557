<template>
  <b-form @submit.prevent="onSubmit" class="align-items-center lista-secciones">
    <!--<code>&lt;AdminarticuloNuevo.vue&gt;</code>-->
    <h3 class="titulo-nuevo-articulo">Nuevo Link.</h3>
    
    <div class="container-details-articulo row">
      <div class="form-group col-md-1">
        <b-form-input
          v-model.number="link.orden"
          type="number"
          placeholder="Orden"
        ></b-form-input>
      </div>
      <div class="form-group col-md-4">
        <b-form-input
          v-model.trim="link.nombre"
          type="text"
          placeholder="Nombre"
        ></b-form-input>
      </div>
      
      <div class="form-group col-md-5">
        <b-form-input
          v-model.trim="link.url"
          type="url"
          placeholder="Url"
        ></b-form-input>
      </div>
      
      <div class="form-group col-md-2 d-flex">
        <div class="container-botton-inline">
          <b-button class="button-accept-sm" v-if="link.nombre && link.orden && link.url" type="submit">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
              <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
            </svg>
          </b-button>
          <b-button class="button-cancel-sm" @click="cancelar">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
              <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
            </svg>
          </b-button>
        </div>
      </div>
      
    </div>
  </b-form>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "AdminLinkNuevo",
  props: ["seccion", "subseccion", "publicacion"],
  data() {
    return {
      ref: firebase.firestore()
      .collection("secciones").doc(this.seccion)
      .collection("subsecciones").doc(this.subseccion)
      .collection("publicaciones").doc(this.publicacion)
      .collection("links"),
      link: {},
      orden: null,
      enabled: false,
    };
  },
  methods: {
    cancelar() {
      this.$emit('accion')
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.link.edit = false;
      this.ref
        .add(this.link)
        .then((docRef) => {
          this.link.nombre = "",
          this.link.orden = null,
          this.link.url = "",
          this.edit = false;
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";
  
  .container-nuevo-documento {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background: $color-bg-02;
    padding: 1rem;

    @include box_shadow(1);
  }
  

</style>
