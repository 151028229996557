<template>
  <svg id="Capa_1" :class="clase" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.59 107.92">
    <title>escudo congreso</title>
    <path class="cls-1" d="M8.81,15.22h.95a1.25,1.25,0,0,1,1.39,1.27h0A1.27,1.27,0,0,1,9.92,17.8H8.81Zm1,1.95a.62.62,0,0,0,.68-.65h0a.62.62,0,0,0-.59-.65H9.49v1.31Z"/>
    <polygon class="cls-1" points="11.5 15.22 13.48 15.22 13.48 15.83 12.18 15.83 12.18 16.22 13.36 16.22 13.36 16.79 12.18 16.79 12.18 17.19 13.49 17.19 13.49 17.8 11.5 17.8 11.5 15.22"/>
    <polygon class="cls-1" points="8.82 27.33 9.39 27.33 9.39 28.14 10.13 28.14 10.13 27.33 10.7 27.33 10.7 29.49 10.13 29.49 10.13 28.67 9.39 28.67 9.39 29.49 8.82 29.49 8.82 27.33"/>
    <rect class="cls-1" x="11.01" y="28.89" width="0.58" height="0.6"/>
    <polygon class="cls-1" points="13.28 28.39 12.6 27.33 13.26 27.33 13.63 27.94 13.99 27.33 14.64 27.33 13.96 28.38 14.67 29.49 14.01 29.49 13.61 28.84 13.21 29.49 12.57 29.49 13.28 28.39"/>
    <path class="cls-1" d="M67,4H10.56A6.72,6.72,0,0,0,4,10.84V97.19a6.64,6.64,0,0,0,6.53,6.72H67a6.65,6.65,0,0,0,6.54-6.72V10.84A6.72,6.72,0,0,0,67,4m4.66,63.79v29.4A4.75,4.75,0,0,1,67,102H10.56A4.77,4.77,0,0,1,5.9,97.19V10.84a4.83,4.83,0,0,1,4.66-4.91H67a4.81,4.81,0,0,1,4.66,4.91Z"/>
    <path class="cls-1" d="M70.39,0H7.21A7.21,7.21,0,0,0,0,7.15v93.62a7.2,7.2,0,0,0,7.21,7.15H70.39a7.18,7.18,0,0,0,7.2-7.15V7.15A7.19,7.19,0,0,0,70.39,0m5.32,67.76v33A5.33,5.33,0,0,1,70.39,106H7.21a5.32,5.32,0,0,1-5.32-5.28V7.15A5.33,5.33,0,0,1,7.21,1.86H70.39a5.33,5.33,0,0,1,5.32,5.29Z"/>
    <path class="cls-1" d="M20,10.8h1a.9.9,0,0,1,1,.91h0c0,.61-.45.93-1.06.93H20.6v.74H20Zm1,1.28c.24,0,.39-.13.39-.33h0c0-.22-.15-.34-.4-.34h-.32v.68Z"/>
    <path class="cls-1" d="M22.26,12.1h0a1.32,1.32,0,0,1,1.3-1.34h0A1.3,1.3,0,0,1,24.91,12s0,0,0,.05h0a1.32,1.32,0,1,1-2.65,0m2,0h0a.66.66,0,0,0-.62-.7h0A.64.64,0,0,0,23,12v0h0a.65.65,0,0,0,.61.69h0a.64.64,0,0,0,.62-.66v0"/>
    <path class="cls-1" d="M25.26,10.8h1a1.25,1.25,0,0,1,1.39,1.27h0a1.27,1.27,0,0,1-1.23,1.31h-1.1Zm1,1.94a.62.62,0,0,0,.68-.65h0a.62.62,0,0,0-.59-.65H26v1.31Z"/>
    <polygon class="cls-1" points="27.95 10.8 29.93 10.8 29.93 11.41 28.62 11.41 28.62 11.8 29.8 11.8 29.8 12.37 28.62 12.37 28.62 12.78 29.94 12.78 29.94 13.38 27.95 13.38 27.95 10.8"/>
    <path class="cls-1" d="M30.29,10.8h1.16a1.06,1.06,0,0,1,.8.28.83.83,0,0,1,.22.6h0a.82.82,0,0,1-.51.79l.59.9h-.79l-.49-.78H31v.78h-.68ZM31.42,12c.23,0,.37-.12.37-.3h0c0-.2-.14-.3-.37-.3H31V12Z"/>
    <polygon class="cls-1" points="33.79 10.8 34.48 10.8 34.48 12.76 35.67 12.76 35.67 13.38 33.79 13.38 33.79 10.8"/>
    <polygon class="cls-1" points="35.96 10.8 37.94 10.8 37.94 11.41 36.63 11.41 36.63 11.8 37.81 11.8 37.81 12.37 36.63 12.37 36.63 12.78 37.95 12.78 37.95 13.38 35.96 13.38 35.96 10.8"/>
    <path class="cls-1" d="M38.2,12.1h0a1.31,1.31,0,0,1,1.28-1.34h0a1.39,1.39,0,0,1,1,.38l-.4.5a.84.84,0,0,0-.59-.24.66.66,0,0,0-.63.69v0h0a.69.69,0,0,0,1.06.61V12.4h-.48v-.52H40.6V13a1.61,1.61,0,0,1-1.07.4,1.29,1.29,0,0,1-1.33-1.25s0,0,0-.05"/>
    <rect class="cls-1" x="41" y="10.8" width="0.69" height="2.58"/>
    <path class="cls-1" d="M42,13l.38-.47a1.23,1.23,0,0,0,.8.31c.18,0,.28-.07.28-.18h0c0-.11-.08-.17-.41-.25-.52-.12-.93-.28-.93-.81h0a.84.84,0,0,1,.84-.84H43a1.49,1.49,0,0,1,1,.34l-.34.5a1.21,1.21,0,0,0-.69-.25c-.16,0-.24.08-.24.17h0c0,.12.09.17.42.25.57.13.92.32.92.8h0c0,.53-.4.84-1,.84A1.67,1.67,0,0,1,42,13"/>
    <polygon class="cls-1" points="44.42 10.8 45.11 10.8 45.11 12.76 46.3 12.76 46.3 13.38 44.42 13.38 44.42 10.8"/>
    <path class="cls-1" d="M47.44,10.79h.66l1,2.6h-.73l-.18-.46h-1l-.17.46h-.72Zm.6,1.58-.27-.73-.28.73Z"/>
    <polygon class="cls-1" points="49.67 11.43 48.93 11.43 48.93 10.8 51.09 10.8 51.09 11.43 50.35 11.43 50.35 13.38 49.67 13.38 49.67 11.43"/>
    <rect class="cls-1" x="51.4" y="10.8" width="0.69" height="2.58"/>
    <polygon class="cls-1" points="52.34 10.8 53.1 10.8 53.66 12.44 54.22 10.8 54.97 10.8 53.97 13.4 53.34 13.4 52.34 10.8"/>
    <path class="cls-1" d="M55,12.1h0a1.33,1.33,0,0,1,2.65,0h0a1.33,1.33,0,0,1-2.65,0m2,0h0a.66.66,0,0,0-.62-.7h0a.64.64,0,0,0-.62.66v0h0a.65.65,0,0,0,.61.69h0a.64.64,0,0,0,.62-.66v0"/>
    <polygon class="cls-1" points="13.84 15.22 14.53 15.22 14.53 17.18 15.72 17.18 15.72 17.8 13.84 17.8 13.84 15.22"/>
    <polygon class="cls-1" points="16.98 15.22 18.96 15.22 18.96 15.83 17.66 15.83 17.66 16.22 18.84 16.22 18.84 16.79 17.66 16.79 17.66 17.19 18.98 17.19 18.98 17.8 16.98 17.8 16.98 15.22"/>
    <path class="cls-1" d="M19.14,17.42l.38-.47a1.24,1.24,0,0,0,.8.31c.18,0,.28-.07.28-.18h0c0-.11-.08-.17-.41-.25-.52-.12-.93-.28-.93-.81h0a.84.84,0,0,1,.83-.85l.17,0a1.51,1.51,0,0,1,1,.34l-.34.5a1.19,1.19,0,0,0-.69-.25c-.16,0-.25.08-.25.17h0c0,.12.08.17.43.25.56.13.92.32.92.8h0c0,.53-.4.84-1,.84a1.66,1.66,0,0,1-1.15-.43"/>
    <polygon class="cls-1" points="22.15 15.85 21.41 15.85 21.41 15.22 23.57 15.22 23.57 15.85 22.83 15.85 22.83 17.8 22.15 17.8 22.15 15.85"/>
    <path class="cls-1" d="M24.4,15.21h.66l1,2.6h-.73l-.18-.46h-1l-.17.46h-.72Zm.6,1.58-.27-.73-.28.73Z"/>
    <path class="cls-1" d="M26.34,15.22h1a1.25,1.25,0,0,1,1.39,1.27h0A1.27,1.27,0,0,1,27.5,17.8H26.39Zm1,1.95a.62.62,0,0,0,.68-.65h0a.62.62,0,0,0-.59-.65H27v1.31Z"/>
    <path class="cls-1" d="M29,16.52h0a1.33,1.33,0,1,1,2.65,0h0a1.32,1.32,0,0,1-1.3,1.34h0a1.31,1.31,0,0,1-1.32-1.3s0,0,0,0m2,0h0a.66.66,0,0,0-.62-.7h0a.64.64,0,0,0-.62.66v0h0a.66.66,0,0,0,.62.7h0a.64.64,0,0,0,.62-.66v0"/>
    <polygon class="cls-1" points="32.92 15.22 33.61 15.22 33.61 17.18 34.8 17.18 34.8 17.8 32.92 17.8 32.92 15.22"/>
    <rect class="cls-1" x="35.1" y="15.22" width="0.69" height="2.58"/>
    <path class="cls-1" d="M36.24,15.22H37.5a.9.9,0,0,1,.67.23.59.59,0,0,1,.16.42h0a.6.6,0,0,1-.38.57.62.62,0,0,1,.49.63h0c0,.44-.34.71-.93.71H36.24Zm1.09,1c.21,0,.32-.07.32-.22h0c0-.14-.11-.22-.31-.22H36.9v.45Zm.1,1c.21,0,.33-.09.33-.24h0c0-.14-.1-.23-.33-.23H36.9v.47Z"/>
    <path class="cls-1" d="M38.75,15.22h1.16a1,1,0,0,1,.8.28.83.83,0,0,1,.22.6h0a.81.81,0,0,1-.51.79l.59.9h-.79l-.5-.78h-.3v.78h-.68Zm1.13,1.24c.23,0,.37-.12.37-.3h0c0-.2-.14-.31-.37-.31h-.45v.62Z"/>
    <polygon class="cls-1" points="41.28 15.22 43.26 15.22 43.26 15.83 41.95 15.83 41.95 16.22 43.13 16.22 43.13 16.79 41.95 16.79 41.95 17.19 43.27 17.19 43.27 17.8 41.28 17.8 41.28 15.22"/>
    <polygon class="cls-1" points="45.3 16.84 44.37 15.22 45.15 15.22 45.65 16.16 46.16 15.22 46.92 15.22 45.99 16.82 45.99 17.8 45.3 17.8 45.3 16.84"/>
    <path class="cls-1" d="M47.92,17.42,48.3,17a1.25,1.25,0,0,0,.8.31c.18,0,.28-.07.28-.18h0c0-.11-.08-.17-.42-.25-.52-.12-.93-.28-.93-.81h0a.85.85,0,0,1,.86-.84H49a1.51,1.51,0,0,1,1,.34l-.34.5a1.19,1.19,0,0,0-.69-.25c-.17,0-.25.08-.25.17h0c0,.12.09.17.42.25.57.13.92.32.92.8h0c0,.53-.4.84-1,.84a1.67,1.67,0,0,1-1.15-.43"/>
    <path class="cls-1" d="M50.31,16.52h0a1.33,1.33,0,1,1,2.65,0h0a1.32,1.32,0,0,1-1.3,1.34h0a1.31,1.31,0,0,1-1.32-1.3s0,0,0,0m2,0h0a.66.66,0,0,0-.62-.7h0a.65.65,0,0,0-.62.68h0a.66.66,0,0,0,.62.7h0a.65.65,0,0,0,.62-.68h0"/>
    <path class="cls-1" d="M53.32,15.22h1.26a.88.88,0,0,1,.67.23.57.57,0,0,1,.16.42h0a.6.6,0,0,1-.38.57.62.62,0,0,1,.49.63h0c0,.44-.34.71-.93.71H53.32Zm1.09,1c.21,0,.32-.07.32-.22h0c0-.14-.1-.22-.31-.22H54v.45Zm.1,1c.21,0,.33-.09.33-.24h0c0-.14-.1-.23-.33-.23H54v.47Z"/>
    <polygon class="cls-1" points="55.83 15.22 57.81 15.22 57.81 15.83 56.51 15.83 56.51 16.22 57.68 16.22 57.68 16.79 56.51 16.79 56.51 17.19 57.83 17.19 57.83 17.8 55.83 17.8 55.83 15.22"/>
    <path class="cls-1" d="M58.17,15.22h1.16a1,1,0,0,1,.8.28.83.83,0,0,1,.22.6h0a.81.81,0,0,1-.51.79l.59.9h-.79l-.5-.78h-.3v.78h-.68Zm1.13,1.24c.23,0,.36-.12.36-.3h0c0-.2-.14-.31-.37-.31h-.45v.62Z"/>
    <path class="cls-1" d="M61.51,15.21h.66l1,2.6h-.73l-.18-.46h-1l-.17.46h-.72Zm.6,1.58-.27-.73-.28.73Z"/>
    <polygon class="cls-1" points="63.45 15.22 64.09 15.22 65.1 16.59 65.1 15.22 65.78 15.22 65.78 17.8 65.18 17.8 64.13 16.39 64.13 17.8 63.45 17.8 63.45 15.22"/>
    <path class="cls-1" d="M66.13,16.52h0a1.33,1.33,0,1,1,2.65,0h0a1.33,1.33,0,1,1-2.65,0m2,0h0a.66.66,0,0,0-.62-.7h0a.64.64,0,0,0-.62.66v0h0a.66.66,0,0,0,.62.7h0a.64.64,0,0,0,.62-.66v0"/>
    <path class="cls-1" d="M21.38,19.64h1a1.25,1.25,0,0,1,1.39,1.27h0a1.27,1.27,0,0,1-1.23,1.31h-1.1Zm1,2a.62.62,0,0,0,.68-.65h0a.62.62,0,0,0-.59-.65H22.1V21.6Z"/>
    <polygon class="cls-1" points="24.07 19.64 26.05 19.64 26.05 20.25 24.74 20.25 24.74 20.64 25.92 20.64 25.92 21.21 24.74 21.21 24.74 21.61 26.06 21.61 26.06 22.22 24.07 22.22 24.07 19.64"/>
    <path class="cls-1" d="M29.37,22a1.32,1.32,0,0,1-2.07-1.1h0a1.33,1.33,0,1,1,2.65,0h0a1.33,1.33,0,0,1-.22.74l.25.23-.34.4Zm-.49-.45-.34-.3.35-.41.33.32a.78.78,0,0,0,0-.25h0a.66.66,0,0,0-.62-.7h0a.65.65,0,0,0-.62.68v0h0a.64.64,0,0,0,.88.65"/>
    <path class="cls-1" d="M30.27,21.09V19.64H31v1.43c0,.37.18.55.45.55s.45-.17.45-.53V19.64h.69v1.43a1,1,0,0,1-1.15,1.2,1,1,0,0,1-1.14-1.18"/>
    <rect class="cls-1" x="32.98" y="19.64" width="0.69" height="2.58"/>
    <polygon class="cls-1" points="34.12 19.64 34.76 19.64 35.77 21 35.77 19.64 36.44 19.64 36.44 22.22 35.84 22.22 34.79 20.81 34.79 22.22 34.12 22.22 34.12 19.64"/>
    <polygon class="cls-1" points="37.48 20.27 36.74 20.27 36.74 19.64 38.9 19.64 38.9 20.27 38.16 20.27 38.16 22.22 37.48 22.22 37.48 20.27"/>
    <path class="cls-1" d="M39.73,19.62h.66l1,2.6H40.7l-.18-.46h-1l-.18.46h-.72Zm.59,1.58L40,20.47l-.28.73Z"/>
    <polygon class="cls-1" points="41.67 19.64 42.31 19.64 43.32 21 43.32 19.64 43.99 19.64 43.99 22.22 43.4 22.22 42.35 20.81 42.35 22.22 41.67 22.22 41.67 19.64"/>
    <path class="cls-1" d="M45.28,19.62h.66l1,2.6h-.73L46,21.76h-1l-.17.46h-.72Zm.6,1.58-.28-.73-.28.73Z"/>
    <path class="cls-1" d="M48.2,19.64h1.16a1,1,0,0,1,.8.28.82.82,0,0,1,.22.6h0a.81.81,0,0,1-.51.79l.59.9h-.79l-.5-.78h-.3v.78H48.2Zm1.13,1.24c.23,0,.37-.12.37-.3h0c0-.2-.14-.3-.37-.3h-.45v.62Z"/>
    <path class="cls-1" d="M50.64,20.94h0a1.33,1.33,0,1,1,2.65,0h0a1.33,1.33,0,1,1-2.65,0m2,0h0a.66.66,0,0,0-.62-.7h0a.65.65,0,0,0-.62.68h0a.66.66,0,0,0,.62.7h0a.64.64,0,0,0,.62-.66v0"/>
    <path class="cls-1" d="M53.56,20.94h0a1.33,1.33,0,0,1,2.65,0h0a1.32,1.32,0,0,1-1.3,1.34h0A1.3,1.3,0,0,1,53.56,21s0,0,0-.05m2,0h0a.66.66,0,0,0-.62-.7h0a.65.65,0,0,0-.62.68h0a.66.66,0,0,0,.62.7h0a.64.64,0,0,0,.62-.66v0"/>
    <polygon class="cls-1" points="14.66 27.33 15.3 27.33 15.77 28.7 16.24 27.33 16.87 27.33 16.03 29.51 15.5 29.51 14.66 27.33"/>
    <rect class="cls-1" x="17.08" y="27.33" width="0.57" height="2.17"/>
    <polygon class="cls-1" points="18.85 27.33 19.42 27.33 19.42 28.97 20.42 28.97 20.42 29.49 18.85 29.49 18.85 27.33"/>
    <polygon class="cls-1" points="20.67 27.33 22.33 27.33 22.33 27.84 21.24 27.84 21.24 28.16 22.23 28.16 22.23 28.64 21.24 28.64 21.24 28.98 22.35 28.98 22.35 29.49 20.67 29.49 20.67 27.33"/>
    <path class="cls-1" d="M22.55,28.42h0a1.1,1.1,0,0,1,1.08-1.12h0a1.17,1.17,0,0,1,.84.31l-.34.42a.71.71,0,0,0-.5-.2.56.56,0,0,0-.53.59h0a.56.56,0,0,0,.52.6h0a.57.57,0,0,0,.33-.09v-.27h-.41v-.44h1v1a1.36,1.36,0,0,1-.9.33,1.09,1.09,0,0,1-1.12-1.06s0,0,0-.06"/>
    <rect class="cls-1" x="24.9" y="27.33" width="0.58" height="2.17"/>
    <path class="cls-1" d="M25.7,29.17l.32-.4a1,1,0,0,0,.67.26c.15,0,.24-.06.24-.15h0c0-.09-.07-.14-.35-.21-.44-.1-.78-.24-.78-.68h0a.71.71,0,0,1,.72-.7h.08a1.27,1.27,0,0,1,.85.28l-.28.42a1,1,0,0,0-.58-.21c-.14,0-.21.06-.21.14h0c0,.1.07.14.36.21.47.11.77.27.77.67h0c0,.44-.33.71-.84.71a1.4,1.4,0,0,1-1-.36"/>
    <polygon class="cls-1" points="27.78 27.33 28.36 27.33 28.36 28.97 29.36 28.97 29.36 29.49 27.78 29.49 27.78 27.33"/>
    <path class="cls-1" d="M30.32,27.31h.55l.88,2.18h-.61L31,29.1h-.8l-.15.39h-.6Zm.5,1.33L30.59,28l-.23.62Z"/>
    <polygon class="cls-1" points="32.19 27.85 31.57 27.85 31.57 27.33 33.38 27.33 33.38 27.85 32.76 27.85 32.76 29.49 32.19 29.49 32.19 27.85"/>
    <path class="cls-1" d="M33.61,28.54V27.33h.58v1.2c0,.31.15.46.38.46s.38-.14.38-.45V27.33h.58v1.2a.88.88,0,0,1-1,1,.87.87,0,0,1-1-1"/>
    <path class="cls-1" d="M35.87,27.33h1a.87.87,0,0,1,.67.23.69.69,0,0,1,.18.5h0a.69.69,0,0,1-.43.66l.49.76h-.66l-.42-.66h-.25v.66h-.57Zm1,1c.2,0,.31-.1.31-.26h0c0-.17-.12-.26-.31-.26h-.37v.52Z"/>
    <path class="cls-1" d="M38.67,27.31h.55l.88,2.18h-.61l-.15-.39h-.8l-.15.39h-.6Zm.5,1.33L39,28l-.23.62Z"/>
    <path class="cls-1" d="M41,28.42h0a1.09,1.09,0,0,1,1.06-1.12h0a1,1,0,0,1,.88.45l-.43.35a.55.55,0,0,0-.45-.25.53.53,0,0,0-.49.57h0a.53.53,0,0,0,.48.58h0a.56.56,0,0,0,.47-.26L43,29a1,1,0,0,1-.92.49A1.07,1.07,0,0,1,41,28.42s0,0,0-.05"/>
    <path class="cls-1" d="M43.15,28.42h0a1.11,1.11,0,1,1,2.23,0h0a1.11,1.11,0,1,1-2.23,0h0m1.64,0h0a.55.55,0,0,0-.52-.58h0a.54.54,0,0,0-.52.56v0h0a.55.55,0,0,0,.51.59h0a.54.54,0,0,0,.53-.55v0"/>
    <polygon class="cls-1" points="45.67 27.33 46.21 27.33 47.06 28.47 47.06 27.33 47.62 27.33 47.62 29.49 47.12 29.49 46.24 28.3 46.24 29.49 45.67 29.49 45.67 27.33"/>
    <path class="cls-1" d="M47.84,29.17l.32-.4a1,1,0,0,0,.67.26c.15,0,.24-.06.24-.15h0c0-.09-.07-.14-.35-.21-.44-.1-.78-.24-.78-.68h0a.71.71,0,0,1,.72-.7h.08a1.26,1.26,0,0,1,.85.28L49.3,28a1,1,0,0,0-.58-.21c-.14,0-.21.06-.21.14h0c0,.1.07.14.36.21.48.11.77.27.77.67h0c0,.44-.33.71-.84.71a1.4,1.4,0,0,1-1-.36"/>
    <polygon class="cls-1" points="50.36 27.85 49.74 27.85 49.74 27.33 51.55 27.33 51.55 27.85 50.93 27.85 50.93 29.49 50.36 29.49 50.36 27.85"/>
    <rect class="cls-1" x="51.82" y="27.33" width="0.57" height="2.17"/>
    <polygon class="cls-1" points="53.28 27.85 52.66 27.85 52.66 27.33 54.47 27.33 54.47 27.85 53.85 27.85 53.85 29.49 53.28 29.49 53.28 27.85"/>
    <path class="cls-1" d="M54.69,28.54V27.33h.58v1.2c0,.31.15.46.38.46s.38-.14.38-.45V27.33h.58v1.2a1,1,0,1,1-1.92,0"/>
    <path class="cls-1" d="M56.89,28.42h0a1.09,1.09,0,0,1,1-1.14H58a1,1,0,0,1,.88.45l-.43.35a.55.55,0,0,0-.45-.25.53.53,0,0,0-.5.56h0A.53.53,0,0,0,58,29h0a.56.56,0,0,0,.47-.26l.43.32a1,1,0,0,1-.91.49,1.08,1.08,0,0,1-1.07-1.09v0"/>
    <rect class="cls-1" x="59.12" y="27.33" width="0.57" height="2.17"/>
    <path class="cls-1" d="M60,28.42h0a1.11,1.11,0,0,1,1.1-1.12h0a1.1,1.1,0,0,1,1.11,1.09s0,0,0,0h0a1.11,1.11,0,1,1-2.23,0h0m1.64,0h0a.55.55,0,0,0-.52-.58h0a.54.54,0,0,0-.52.56v0h0a.55.55,0,0,0,.51.59h0a.54.54,0,0,0,.53-.55v0"/>
    <polygon class="cls-1" points="62.53 27.33 63.07 27.33 63.91 28.47 63.91 27.33 64.48 27.33 64.48 29.49 63.98 29.49 63.1 28.3 63.1 29.49 62.53 29.49 62.53 27.33"/>
    <path class="cls-1" d="M65.56,27.31h.55L67,29.49h-.61l-.15-.39h-.8l-.15.39h-.6Zm.5,1.33L65.83,28l-.23.62Z"/>
    <polygon class="cls-1" points="67.2 27.33 67.77 27.33 67.77 28.97 68.77 28.97 68.77 29.49 67.2 29.49 67.2 27.33"/>
    <path class="cls-1" d="M56.63,87H22.09a.53.53,0,0,0-.53.53v2.73a.53.53,0,0,0,.51.55h5.25V93a.52.52,0,0,0,.5.54h23a.54.54,0,0,0,.53-.54V90.8H56.6a.52.52,0,0,0,.53-.51s0,0,0,0V87.52a.52.52,0,0,0-.51-.53h0M36.51,85.31h5.75a.52.52,0,0,0,.43-.21.58.58,0,0,0,.08-.51L39.9,76.38a.53.53,0,0,0-.51-.36.52.52,0,0,0-.5.36L36,84.6a.58.58,0,0,0,.07.51.52.52,0,0,0,.43.21m-11.5,0h5.76a.56.56,0,0,0,.45-.21.59.59,0,0,0,.07-.51l-2.89-8.21a.54.54,0,0,0-.52-.36.53.53,0,0,0-.5.36l-2.9,8.22a.62.62,0,0,0,.07.51.56.56,0,0,0,.44.21m23,0h5.74a.56.56,0,0,0,.44-.22.57.57,0,0,0,.07-.51l-2.87-8.21a.53.53,0,0,0-1,0L47.49,84.6a.54.54,0,0,0,.08.51.53.53,0,0,0,.43.22"/>
    <path class="cls-1" d="M58.57,60.74a.44.44,0,0,0-.45-.32l-5.46.4c0-.14-1.28-5-1.28-5a.43.43,0,0,0-.42-.35.44.44,0,0,0-.44.35l-1.29,5-5.44-.4a.48.48,0,0,0-.47.32v.14a.47.47,0,0,0,.22.38L48.12,64l-2.06,4.76v.17a.46.46,0,0,0,.73.36L51,66l4.16,3.34a.45.45,0,0,0,.55,0,.46.46,0,0,0,.18-.36v-.17l-2-4.76,4.6-2.71a.46.46,0,0,0,.23-.38Z"/>
    <path class="cls-1" d="M35.2,69.59v-.12a8.7,8.7,0,0,1-1.47-4.33,4.68,4.68,0,0,1,1.4-3.29.47.47,0,0,0,.12-.31.7.7,0,0,0-.27-.6,1.35,1.35,0,0,0-1.24,0,2.86,2.86,0,0,1-1,.19,12,12,0,0,1-2-.47c-1.27-.37-1.88-.53-2.25-.26a.57.57,0,0,0-.23.47.51.51,0,0,0,.22.39c.2.11.21.26-.18,1-.2.4-.4.77-.18,1.11a.54.54,0,0,0,.37.27c.07,0,.35.11.68-.41a.92.92,0,0,1,.46-.33,1.07,1.07,0,0,1,.54,0,2.14,2.14,0,0,1,.86.5,1.51,1.51,0,0,1,.44.88,2.56,2.56,0,0,1-1,2.45,3.28,3.28,0,0,1-3.11,0,2.27,2.27,0,0,1-.94-2v-.1A3.82,3.82,0,0,1,28,62.31a.54.54,0,0,0,.2-.31.49.49,0,0,0-.2-.4l-7.2-5.75a.44.44,0,0,0-.59,0,.46.46,0,0,0,0,.62A20.17,20.17,0,0,1,23,60.31l.34.63c.18.34.37.68.44.83,1,2.14.64,4.73-1.11,7.69a.47.47,0,0,0,0,.5c.14.21.49.49,1.21.14,3.71-1.77,7.43-1.78,10,0,.33.25.76.49,1.13.25a.63.63,0,0,0,.25-.54.58.58,0,0,0,0-.19"/>
    <path class="cls-1" d="M62.51,51.46H57a18.74,18.74,0,0,0-.31-3l-.14-.8-3.34.7,3.24-1.1-.22-.74A17.71,17.71,0,0,0,55,43.61l-.37-.73-3.11,1.69,2.89-2.06L54,41.87a17.45,17.45,0,0,0-2-2.47l-.56-.59-2.6,2.59,2.26-2.9-.59-.5a18.09,18.09,0,0,0-2.63-1.81l-.7-.4-1.8,3.33,1.38-3.54-.72-.3a18.56,18.56,0,0,0-3.06-1l-.79-.17-.79,3.78L41.73,34l-.79,0a17.11,17.11,0,0,0-3.22,0l-.79.07.31,3.68-.76-3.62-.77.16a18,18,0,0,0-3.08.94l-.73.29,1.4,3.61-1.84-3.41-.7.39a17.31,17.31,0,0,0-2.66,1.81l-.6.49,2.33,3-2.68-2.69-.56.57a17.82,17.82,0,0,0-2,2.49l-.43.63,3,2.16L23.9,42.81l-.38.71a18.67,18.67,0,0,0-1.21,3l-.22.74,3.41,1.17L22,47.64l-.16.8a17.91,17.91,0,0,0-.3,3H16.22v40.1a4.15,4.15,0,0,0,1.94,3.94,8,8,0,0,0,4,.8H56.65a8.07,8.07,0,0,0,4-.73c1.12-.66,1.85-1.87,1.85-4ZM61.6,73.39H39.79v-21H61.6Zm-5.7-24.8a17,17,0,0,1,.28,2.87H46.92a9.27,9.27,0,0,0-.13-1Zm-1.59-4.65a16.84,16.84,0,0,1,1.14,2.79l-8.84,3a8.28,8.28,0,0,0-.53-1.36Zm-2.86-4a16.07,16.07,0,0,1,1.89,2.36L45.7,47.73a8.34,8.34,0,0,0-.88-1.17Zm-3.93-3A16.52,16.52,0,0,1,50,38.59L44.25,46a7.66,7.66,0,0,0-1.2-.89ZM42.85,35.1a17.69,17.69,0,0,1,2.92.93l-3.42,8.72a7.23,7.23,0,0,0-1.42-.49Zm-3.57-.37a16.14,16.14,0,0,1,1.62.08l-.78,9.31a7.38,7.38,0,0,0-.81,0h-.7l-.78-9.31c.47,0,1-.06,1.45-.06M33,35.93a17,17,0,0,1,2.94-.9l1.91,9.16a7.26,7.26,0,0,0-1.44.47Zm-4.32,2.56a17,17,0,0,1,2.54-1.71L35.64,45a7.88,7.88,0,0,0-1.22.88ZM25.3,42.18a18,18,0,0,1,1.93-2.37l6.66,6.66A8.36,8.36,0,0,0,33,47.65Zm-2.14,4.49a18.2,18.2,0,0,1,1.15-2.81l8.29,4.48a8.13,8.13,0,0,0-.6,1.37Zm-.46,1.88,9.16,1.92a8.29,8.29,0,0,0-.14,1H22.36a17.28,17.28,0,0,1,.3-2.91m-5.53,3.82H38.89v21H17.12ZM61.64,91.52c0,1.81-.54,2.74-1.42,3.26a7.39,7.39,0,0,1-3.58.59H22.12a7,7,0,0,1-3.49-.65,3.27,3.27,0,0,1-1.51-3.16V74.31H61.64Z"/>
  </svg>
</template>

<script>

export default {
  name: "AtomLogoXVI3erAnioHotizontal",
  props: ["clase"],
}
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";

  .logocongreso,
  .logocongreso-blanco{
    height: 50px;
    margin: 0 0.25rem;
    @include media-breakpoint-up(md){
      height: 65px;
      margin: 0 0.5rem;
    }
  }

  .logocongreso {
    .cls-1{fill:#474c56;}
  }
  .logocongreso-blanco {
    .cls-1{  fill:#ffffff; }
  }
</style>