<template>
	<div class="container container-login">
		<Login />
	</div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'

export default {
  name: 'ViewLogin',
  components: {
    Login
  }
}
</script>

<style lang="scss" scoped>
  @import '../scss/abstracts/variables';
  
  .container-login {
    margin-top: 5rem;
    width: 85% !important;
    @include media-breakpoint-up(md) {
			width: 50% !important;
		}
    @include media-breakpoint-up(lg) {
			width: 30% !important;
		}
  }

</style>
