<template>
  <div class="logo-header-title">
    <AtomLogoMascaron clase="logoHeader" />
    <h1>Ley de Consulta a los Pueblos Indígenas y Afroamericanos</h1>
  </div>
</template>

<script>
import AtomLogoMascaron from "./AtomLogoMascaron.vue"

export default {
  name: "MoleculeLogoMascaronHeader",
  components: {
    AtomLogoMascaron
  }
}
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";
  .logo-header-title{
    display: flex;
    align-items: center;
    
    svg {
      height: 50px;
      @include media-breakpoint-up(md) {
        height: 60px;
      }
    }
    h1 {
      font-size: 0.5rem;
      font-family: $font-03;
      text-transform: uppercase;
      border-left: 1px solid #525151;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.35rem;
      width: 90px;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: 0.55rem;
        padding-left: 0.5rem;
        width: 100px;
      }
    }
  }

</style>
