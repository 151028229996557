<template>
  <div>
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado documentos
    </p>
    <ul v-else>
      <li class="mb-4"
        v-for="i in items" :key="i.key"
      >
        <div v-if="i.edit === false" class="px-4 align-items-center lista-secciones">
          <div class="titulo-documento">
            <span class="estatus-articulo">Estatus:
              <code v-if="i.activo" class="estatus-activo">Publicado</code>
              <code v-else class="estatus-inactivo">No Publicado</code>
            </span>
            <b-button
              class="button-edit"
              v-b-tooltip.hover
              title="Editar"
              @click="setEditArticle(i.key)"
            >
              <b-icon icon="pen" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              class="button-delete"
              @click.stop="deleteDocument(i.key)"
              v-b-tooltip.hover
              title="Eliminar"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>
          </div>
          <div class="d-flex lugar-fecha">
            <span>{{i.fecha}} - {{i.hora}}hrs.</span>
            <span class="lugar">{{i.lugar}}</span>
          </div>
          <h1 class="titulo-articulo">{{i.titulo}}</h1>
          <div class="container-articulo" v-html="i.articulo"></div>
        </div>
      
        <div v-else>
          <div class="container-details-articulo row">
            <div class="form-group col-md-10">
              <b-form-input
                v-model.trim="i.titulo"
                type="text"
                placeholder="Titulo"
              ></b-form-input>
            </div>
            <div class="form-group col-md-2">
              <b-form-input
                v-model.number="i.orden"
                type="number"
                placeholder="Orden"
              ></b-form-input>
            </div>
            <div class="form-group col-md-2">
              <b-form-input
                v-model="i.fecha"
                type="date"
                placeholder="Fecha"
              ></b-form-input>
            </div>
            <div class="form-group col-md-2">
              <b-form-input
                class="col-2"
                v-model="i.hora"
                type="time"
                placeholder="Hora"
              ></b-form-input>
            </div>
            <div class="form-group col-md-4">
              <b-form-input
                class="col-2"
                v-model="i.lugar"
                type="text"
                placeholder="lugar"
              ></b-form-input>
            </div>
            <div class="form-group col-md-4 d-flex">
              <div class="container-activar">
                <p>¿Activar Artículo?</p>
                <switches v-model="i.activo" color="blue"></switches>
              </div>
              <div class="container-botton-inline">
                <b-button class="button-accept-sm" @click="setUpdateArticle(i)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                  </svg>
                </b-button>
                <b-button class="button-cancel-sm" @click="cancelEditArticle(i.key)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                    <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                  </svg>
                </b-button>
              </div>
            </div>
          </div>
          <vue-editor id="description" v-model="i.articulo" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "../firebase";
import router from "../router";
import Switches from "vue-switches";
import { VueEditor } from "vue2-editor";

export default {
  name: "AdminArticuloLista",
  props: ["seccion", "subseccion", "publicacion"],
  components: {
    VueEditor,
    Switches,
  },
  data() {
    return {
      ref: firebase.firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones")
        .doc(this.publicacion)
        .collection("articulos"),
      items: {},
      showEdit: true,
      enabled: false,
    };
  },
  created() {
    this.ref.onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });
      //console.log(this.items)
    });
  },
  methods: {
    details(subseccion) {
      router.push({ name: "AdminSubseccion", params: { id: this.seccion, subid: subseccion } });
    },
    setEditArticle(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    setUpdateArticle(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          titulo: id.titulo,
          orden: id.orden,
          fecha: id.fecha,
          hora: id.hora,
          lugar: id.lugar,
          articulo: id.articulo,
          activo: id.activo,
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
          this.enabled === false;
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    cancelEditArticle(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteDocument(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Documento?",
        text: "¡Una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#657f61",
        cancelButtonColor: "#99494b",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("secciones")
            .doc(this.seccion)
            .collection("subsecciones")
            .doc(this.subseccion)
            .collection("publicaciones")
            .doc(this.publicacion)
            .collection("articulos")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error al eliminar el documento: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
    getURLFile: function(url) {
      this.urlFile = url;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";

  .lugar-fecha {
    span {
      font-family: $font-02;
      font-size: 0.65rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    .lugar {
      margin: 0 1.5rem;
    }
  }
  .container-articulo {
    /deep/ h1 {
      font-family: $font-03;
      font-size: 1.5rem;
      font-weight: 600;
      margin: 1.5rem 0rem;

    }
    /deep/ h2 {
      font-size: 1.2rem;
      font-weight: 700;
      margin: 1.5rem 0rem;
    }
    /deep/ h3 {
      font-size: 1rem;
      font-weight: 700;
      margin: 1.5rem 0rem;
    }
    /deep/ p,
    /deep/ li {
      font-weight: 300;
      text-align: justify;
    }
  }
</style>